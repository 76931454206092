import Routes from '@/components/common/routes';

export const redirectUrlPostUserCreation = (user: any) => {
  let uri = '';
  if (user.userName && user.referralCode) {
    uri = Routes.QUESTS;
  } else if (!user.userName) {
    uri = Routes.UPDATE_USERNAME;
  } else if (!user.referralCode) {
    uri = Routes.REFERRAL;
  }
  return uri;
};
