import React from 'react';

import Username from '@/features/onboard/user';

const UsernamePage = () => {
  return <Username />;
};

export default UsernamePage;

export function Head() {
  return (
    <>
      <title>Super Champs HQ - Update Username</title>
    </>
  );
}
