import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';

import ApiUser from '@/api/user';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import { loginBiEvents } from '@/bi/events/login-bi-events';
import {
  getCurrentTabForBi,
  getSeasonStartTime,
  localStorage,
  isBrowser,
  sha256,
} from '@/common/utils';
import Routes from '@/components/common/routes';
import { useAuth } from '@/context/auth-context';

import { redirectUrlPostUserCreation } from '../auth-v2/helper';

const Username = () => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState<any>({});
  const [userNameError, setUserNameError] = useState<string>('');
  const [userNameSuccess, setUserNameSuccess] = useState<string>('');
  const [isUsernameButtonDisabled, setUserNameButtonDisabled] = useState(false);

  const userNameExists = !!user?.appUser?.user.userName;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserDetails((data: any) => ({ ...data, [name]: value }));
  };

  const handleKeyUp = async (e: any) => {
    if (e.key === 'Enter') await updateUserName();
  };

  const updateUserName = async () => {
    setUserNameError('');
    // deep copy user object to avoid mutating it
    const updatedUser = JSON.parse(JSON.stringify(user?.appUser));
    if (userDetails.userName) {
      updatedUser.user.userName = userDetails.userName;
    } else {
      setUserNameError('Username Field Cannot Be Empty.');
      return;
    }
    updateUserDetails(updatedUser);
  };

  const updateReferralCode = async (updatedUser: any) => {
    const userId = user?.appUser?.user.id;
    if (!userId) {
      return;
    }
    // auto updating referral id as well in case of any
    const rId = localStorage.getItem('REFERRAL_ID');
    if (rId) {
      updatedUser.user.referralCode = rId;
      try {
        const data = await ApiUser.updateUser(userId, updatedUser);
        if (data) {
          localStorage.setItem('REFERRAL_CODE_UPDATED', 'true');
          localStorage.removeItem('REFERRAL_ID');
        }
      } catch (e: any) {
        console.log('🚀 ~ Username ~ e?.data:', e?.data);
        loginBiEvents.stateChange({
          str_field1: 'error',
          str_field2: 'referral_screen',
          str_field3: getCurrentTabForBi(),
          int_field0: getSeasonStartTime(),
          str_field8: e?.data?.invalidParams[0]?.errorCode || 'invalid entry',
          str_field9: '',
          str_field11:
            !!updatedUser.referralCode || !!user?.appUser?.user?.referralCode,
          str_field12:
            !!updatedUser.userName || !!user?.appUser?.user?.userName,
          str_field13:
            updatedUser.referralCode || user?.appUser?.user?.referralCode,
          str_field14: updatedUser.userName || user?.appUser?.user?.userName,
        });
      }
      // redirects to REFERRAL or QUESTS based on the logic
      setTimeout(() => {
        const url = redirectUrlPostUserCreation(updatedUser.user);
        console.log('🚀 ~ setTimeout ~ url:', url);
        navigate(url);
      }, 2000);
    } else {
      // redirects to REFERRAL or QUESTS based on the logic
      setTimeout(() => {
        const url = redirectUrlPostUserCreation(updatedUser.user);
        console.log('🚀 ~ setTimeout ~ url:', url);
        navigate(url);
      }, 2000);
    }
  };

  const updateUserDetails = async (updatedUser: any) => {
    const userId = user?.appUser?.user.id;
    if (!userId) {
      return;
    }
    if (localStorage.getItem('USERNAME_UPDATED') != null) {
      return;
    }
    try {
      const data = await ApiUser.updateUser(userId, updatedUser);
      if (data) {
        // console.log('🚀 ~ Username ~ data:', data);
        localStorage.setItem('USERNAME_UPDATED', 'true');
        setUserNameButtonDisabled(true);
        setUserNameSuccess('Username Created Successfully.');
        loginBiEvents.click({
          str_field1: 'continue',
          str_field2: 'username_screen',
          str_field3: getCurrentTabForBi(),
          int_field0: getSeasonStartTime(),
          str_field11:
            !!userDetails.referralCode || !!user?.appUser?.user?.referralCode,
          str_field12:
            !!userDetails.userName || !!user?.appUser?.user?.userName,
          str_field13:
            userDetails.referralCode || user?.appUser?.user?.referralCode,
          str_field14: userDetails.userName || user?.appUser?.user?.userName,
        });

        try {
          if (isBrowser()) {
            const emailHash = sha256(user?.appUser?.user?.email || '');
            const userIdHash = sha256(user?.appUser?.user?.id ?? '');
            const eventId = 'CR_' + Math.floor(Date.now() / 1000);
            if (window.fbq) {
              window.fbq(
                'track',
                'CompleteRegistration',
                {
                  currency: 'USD',
                  value: 1,
                  user_id: userId,
                  em: emailHash,
                },
                {
                  eventID: eventId,
                },
              );
            }
            commonBiEvents.fbConversionPixel({
              str_field2: emailHash,
              str_field3: 'CompleteRegistration',
              str_field4: eventId,
              str_field5: userIdHash,
            });
          }
        } catch (e) {
          console.log('Error firing pixel event', e);
        }

        try {
          if (isBrowser() && window.gtag) {
            const emailHash = sha256(user?.appUser?.user?.email || '');
            window.gtag('event', 'conversion', {
              send_to: 'AW-16648527893/0SvRCOuu_scZEJXA0YI-',
              value: 1.0,
              currency: 'USD',
              enhanced_conversions: {
                email: emailHash,
              },
            });
          }
        } catch (e) {
          console.log('Error firing pixel event', e);
        }

        // trying to update referral code automatically
        updateReferralCode(updatedUser);
      } else {
        setUserNameError('Username already taken. Please choose another.');
      }
    } catch (e: any) {
      console.log('🚀 ~ Username ~ e?.data:', e?.data);
      let message = '';
      message = 'Username already taken. Please choose another.';
      setUserNameError(message);

      loginBiEvents.stateChange({
        str_field1: 'error',
        str_field2: 'username_screen',
        str_field3: getCurrentTabForBi(),
        int_field0: getSeasonStartTime(),
        str_field8: e?.data?.invalidParams[0]?.errorCode || 'invalid entry',
        str_field9: message,
        str_field11:
          !!updatedUser.user.referralCode ||
          !!user?.appUser?.user?.referralCode,
        str_field12:
          !!updatedUser.user.userName || !!user?.appUser?.user?.userName,
        str_field13:
          updatedUser.user.referralCode || user?.appUser?.user?.referralCode,
        str_field14: updatedUser.user.userName || user?.appUser?.user?.userName,
      });
    }
  };

  const focusOnUsernameField = () => {
    const usernameField = document.getElementById('username-field');
    if (usernameField) {
      usernameField.focus();
    }
  };

  useEffect(() => {
    focusOnUsernameField();
    loginBiEvents.view({
      str_field1: 'username_screen',
      str_field3: getCurrentTabForBi(),
      int_field0: getSeasonStartTime(),
      str_field11:
        !!userDetails.referralCode || !!user?.appUser?.user?.referralCode,
      str_field12: !!userDetails.userName || !!user?.appUser?.user?.userName,
      str_field13:
        userDetails.referralCode || user?.appUser?.user?.referralCode,
      str_field14: userDetails.userName || user?.appUser?.user?.userName,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && user?.suggestedUserName) {
      setUserDetails((data: any) => ({
        ...data,
        userName: user.suggestedUserName,
      }));
    }
    if (!user) {
      navigate(Routes.LOGIN);
    }
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem('USERNAME_UPDATED') != null) {
      navigate(Routes.QUESTS);
    }
  }, []);

  return (
    <div className="referral-page">
      <div className="logo"></div>
      <div className="referral-form">
        {!userNameExists && (
          <div className="mb-4 flex-column-center">
            <h2>CREATE YOUR USERNAME *</h2>
            <div className="flex aic jcc g-1">
              <input
                type="text"
                name="userName"
                id="username-field"
                defaultValue={user?.suggestedUserName || userDetails.userName}
                className="referral-input"
                onChange={handleInputChange}
                onKeyUp={handleKeyUp}
              />
              <button
                onClick={updateUserName}
                className={
                  isUsernameButtonDisabled
                    ? 'grey-button-v2 small'
                    : 'magenta-button-v2 small'
                }
                disabled={isUsernameButtonDisabled}
              >
                CREATE
              </button>
            </div>
            {userNameError && (
              <div className="input-error mt-2 ml-n110">{userNameError}</div>
            )}
            {userNameSuccess && (
              <div className="input-success mt-2 ml-n110">
                {userNameSuccess}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Username;
